import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 班次设置 - 列表分页
 * @param params
 */
export function getShiftSettingList(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/shift/page',
    method: 'get',
    params,
  });
}

/**
 * 班次设置 - 列表
 * @param params
 */
export function getShiftSettingDatas(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/shift/list',
    method: 'get',
    params,
  });
}

/**
 * 班次设置 - 创建
 * @param params
 */
export function createShiftSetting(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/shift/create',
    method: 'post',
    data: params,
  });
}

/**
 * 班次设置 - 删除
 * @param params
 */
export function deleteShiftSetting(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/shift/delete',
    method: 'delete',
    params,
  });
}

/**
 * 班次设置 - 修改
 * @param params
 */
export function updateShiftSetting(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/shift/update',
    method: 'post',
    data: params,
  });
}

/**
 * 班次设置 - 检查班次是否已添加考勤组
 * @param params
 */
export function checkShiftExist(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/shift/getAttendanceGroupListByShiftId',
    method: 'get',
    params,
  });
}
