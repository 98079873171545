<template>
  <div class="app-container shift-setting-list">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createShifts">新建班次</el-button>
        <el-button
          plain
          icon="el-icon-refresh-right"
          v-preventReClick
          @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:shiftNameSlot="row">
          <span
            class="shift-color-span"
            :style="{
              background: row.datas.shiftColor,
            }"
          >
            {{
              row.datas.shiftShortName
                ? row.datas.shiftShortName.substring(0, 2)
                : "默"
            }}
          </span>
          <span>
            {{ row.datas.shiftName }}
          </span>
        </template>
        <template v-slot:workTimeSlot="row">
          <span v-if="row.datas.workTime">
            <span
              class="single-time-span"
              v-for="(item, index) in JSON.parse(row.datas.workTime)"
              :key="index"
            >
              <span>
                {{ item.startTime.time }}
                <span
                  class="next-day-span"
                  v-show="item.startTime.workDay === 'nextDay'"
                >
                  次日
                </span>
              </span>
              -
              <span>
                {{ item.endTime.time }}
                <span
                  class="next-day-span"
                  v-show="item.endTime.workDay === 'nextDay'"
                >
                  次日
                </span>
              </span>
            </span>
          </span>
          <span v-else> - </span>
        </template>
      </finalTable>
    </div>
    <!-- 新增&编辑弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="dialogCloseHandler"
      width="728px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>{{ dialogTitle }}</span>
      </template>
      <ShiftSettingDialog
        v-if="dialogVisible"
        :dataset="modifyDatas"
        @close="dialogCloseHandler"
      />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import ShiftSettingDialog from "./shiftSettingDialog.vue";
import {
  getShiftSettingList,
  deleteShiftSetting,
  checkShiftExist,
} from "@/api/business/shifts/shiftSetting.js";
export default {
  name: "shiftSettingList",
  components: {
    finalTable,
    ShiftSettingDialog,
  },
  data() {
    return {
      modifyDatas: {},
      dialogVisible: false,
      dialogTitle: "新建班次",
      loadingFlag: false,
      requestParams: {
        current: 1,
        rowCount: 10,
      },
      dataset: {
        // 是否需要分页
        paginationConfig: {
          need: true,
        },
        // 是否需要查询行
        filterLine: {
          show: true,
        },
        // 行勾选
        selection: {
          need: false,
        },
        header: [
          {
            prop: "shiftName",
            label: "班次名称",
            width: "180",
          },
          {
            prop: "shiftShortName",
            label: "班次简称",
            width: "120",
          },
          {
            prop: "workTime",
            label: "班次时段",
            width: "",
          },
          {
            prop: "shiftDuration",
            label: "班次时长",
            width: "180",
          },
          {
            prop: "description",
            label: "班次说明",
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "120",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          shiftName: {
            type: "input",
            label: "班次名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入班次名称",
            prefixIcon: "el-icon-search",
          },
          shiftShortName: {
            type: "input",
            label: "班次简称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入班次简称",
            prefixIcon: "el-icon-search",
          },
        },
        // 表格内容配置
        detailConfig: {
          shiftName: {
            type: "slot",
            slotName: "shiftNameSlot",
          },
          workTime: {
            type: "slot",
            slotName: "workTimeSlot",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "modify",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "修改",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
                hideProp: "isDefault",
                hideValue: true,
              },
            ],
          },
        },
        advanceFilterConfig: {
          deviceName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          productKey: {
            inline: true,
            value: "",
          },
          nodeType: {
            inline: true,
            value: "",
          },
          deviceStatus: {
            inline: true,
            value: "",
          },
          enableStatus: {
            inline: true,
            value: "",
          },
          creationDate: {
            type: "dateRange",
            label: "注册时间",
            value: [],
          },
          activationTime: {
            type: "dateRange",
            label: "激活时间",
            value: [],
          },
          lastOnlineTime: {
            type: "dateRange",
            label: "最后上线时间",
            value: [],
          },
          tagList: {
            type: "tagList",
            label: "标签",
            tagType: "device",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getTableList();
  },
  methods: {
    dialogCloseHandler(freshFlag) {
      this.dialogVisible = false;
      freshFlag === true && this.getTableList();
    },
    getTableList() {
      this.loadingFlag = true;
      getShiftSettingList(this.requestParams)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    freshHandler() {
      this.getTableList();
    },
    createShifts() {
      this.modifyDatas = {};
      this.dialogTitle = "新建班次";
      this.dialogVisible = true;
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "updateSelectionList") {
        this.device.multipleSelection = datas.list.map((item) => item.deviceId);
      } else if (datas.type === "switchEvent") {
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "modify":
            this.modifyHandler(datas.row);
            break;
          case "delete":
            this.deleteHandler(datas.row.id);
            break;
        }
      }
    },
    modifyHandler(datas) {
      checkShiftExist({ id: datas.id }).then((res) => {
        const existList = res.data
          ? res.data.map((item) => {
              return item.attendanceGroupName;
            })
          : [];
        // 【确定编辑“xx”吗?  当前班次已被以下考勤组使用，编辑当前班次，考勤组内班次会同步更改】
        if (existList.length) {
          this.$confirm(
            `确定要编辑吗？当前班次已添加到考勤组：【${existList.join(
              "，"
            )}】，编辑当前班次，考勤组内班次会同步更改`,
            this.$t("commons.warning"),
            {
              confirmButtonText: this.$t("commons.confirm"),
              cancelButtonText: this.$t("commons.cancel"),
              type: "warning",
            }
          )
            .then(() => {
              this.dialogTitle = "修改班次";
              this.modifyDatas = datas;
              this.dialogVisible = true;
            })
            .catch((error) => {
              console.log(`未删除，原因 => ${error}`);
            });
        } else {
          this.dialogTitle = "修改班次";
          this.modifyDatas = datas;
          this.dialogVisible = true;
        }
      });
    },
    deleteHandler(id) {
      checkShiftExist({ id }).then((res) => {
        const existList = res.data
          ? res.data.map((item) => {
              return item.attendanceGroupName;
            })
          : [];

        if (existList.length) {
          this.$confirm(
            `该班次已添加到考勤组：${existList.join("，")}。不允许删除！`,
            this.$t("commons.warning"),
            {
              confirmButtonText: "知道了",
              showCancelButton: false,
              type: "warning",
            }
          );
        } else {
          this.$confirm("确定要删除该班次吗？", this.$t("commons.warning"), {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }).then(() => {
            deleteShiftSetting({
              id,
            }).then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              this.getTableList();
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.shift-setting-list {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
  .shift-color-span {
    display: inline-block;
    height: 24px;
    width: 40px;
    border-radius: 4px;
    color: #ffffff;
    margin-right: 15px;
    text-align: center;
  }
  .single-time-span {
    margin-right: 12px;
  }
  .next-day-span {
    color: #1a4cec;
    font-size: 12px;
  }
}
</style>