var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shift-setting-dialog" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "班次名称", prop: "shiftName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "20" },
                        model: {
                          value: _vm.ruleForm.shiftName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "shiftName", $$v)
                          },
                          expression: "ruleForm.shiftName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "班次简称", prop: "shiftShortName" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "2" },
                        model: {
                          value: _vm.ruleForm.shiftShortName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "shiftShortName", $$v)
                          },
                          expression: "ruleForm.shiftShortName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "班次说明", prop: "description" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", "show-word-limit": "" },
                    model: {
                      value: _vm.ruleForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "description", $$v)
                      },
                      expression: "ruleForm.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "班次颜色", prop: "shiftColor" } },
                [
                  _c(
                    "div",
                    { staticClass: "color-container" },
                    [
                      _c("div", { staticClass: "default-color-outside" }, [
                        _c(
                          "div",
                          {
                            staticClass: "default-color-inside",
                            style: {
                              background: _vm.ruleForm.shiftColor,
                            },
                          },
                          [
                            _c("span", { staticClass: "default-text" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.ruleForm.shiftShortName
                                      ? _vm.ruleForm.shiftShortName.substring(
                                          0,
                                          1
                                        )
                                      : "默"
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        ),
                        _c("span", { staticClass: "preview-span" }, [
                          _vm._v(" 预览 "),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "color-items" },
                        _vm._l(_vm.colorItems, function (item, index) {
                          return _c("div", {
                            key: index,
                            staticClass: "single-item",
                            style: {
                              background: item,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setSettingColor(item)
                              },
                            },
                          })
                        }),
                        0
                      ),
                      _c("span", { staticClass: "customer-text" }, [
                        _vm._v(" 自定义 "),
                      ]),
                      _c("el-color-picker", {
                        on: {
                          change: function ($event) {
                            return _vm.setSettingColor()
                          },
                        },
                        model: {
                          value: _vm.ruleForm.shiftColor,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "shiftColor", $$v)
                          },
                          expression: "ruleForm.shiftColor",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "班次时段", prop: "workTime" } },
                _vm._l(_vm.workTimeList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "work-time-container" },
                    [
                      _c("div", [
                        _c(
                          "span",
                          { staticClass: "time-picker-span" },
                          [
                            _c("el-time-picker", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                format: "HH:mm",
                                "value-format": "HH:mm",
                                placeholder: "开始时间",
                              },
                              model: {
                                value: item.startTime,
                                callback: function ($$v) {
                                  _vm.$set(item, "startTime", $$v)
                                },
                                expression: "item.startTime",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.secondStart,
                                    expression: "item.secondStart",
                                  },
                                ],
                                staticClass: "second-day-span",
                              },
                              [_vm._v("\n                次日\n              ")]
                            ),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "split-span" }, [
                          _vm._v(" 至 "),
                        ]),
                        _c(
                          "span",
                          { staticClass: "time-picker-span" },
                          [
                            _c("el-time-picker", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                format: "HH:mm",
                                "value-format": "HH:mm",
                                placeholder: "结束时间",
                              },
                              model: {
                                value: item.endTime,
                                callback: function ($$v) {
                                  _vm.$set(item, "endTime", $$v)
                                },
                                expression: "item.endTime",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.secondEnd,
                                    expression: "item.secondEnd",
                                  },
                                ],
                                staticClass: "second-day-span",
                              },
                              [_vm._v("\n                次日\n              ")]
                            ),
                          ],
                          1
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.workTimeList.length > 1,
                              expression: "workTimeList.length > 1",
                            },
                          ],
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              return _vm.deleteWorkTimeLine(index)
                            },
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "work-time-single" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.dealTime(
                                item.startTime,
                                item.endTime,
                                item.secondStart,
                                item.secondEnd,
                                true
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    disabled: _vm.workTimeList.length <= 2,
                    content: "最多添加三个时间段",
                    placement: "right",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        plain: "",
                        icon: "el-icon-plus",
                      },
                      on: { click: _vm.addTimeLine },
                    },
                    [_vm._v("添加时段")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "total-work-time" }, [
        _c(
          "span",
          {
            staticClass: "work-time-text",
            style: {
              color: _vm.timeDiff > 86400000 ? "#ff0000" : "#000000",
            },
          },
          [
            _vm._v(
              "\n      班次总时长：" +
                _vm._s(_vm.formatDuring(_vm.timeDiff)) +
                "\n    "
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "footer-container" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close", false)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }