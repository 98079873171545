<template>
  <div class="shift-setting-dialog">
    <el-form
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="班次名称" prop="shiftName">
            <el-input v-model="ruleForm.shiftName" maxlength="20"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="班次简称" prop="shiftShortName">
            <el-input
              v-model="ruleForm.shiftShortName"
              maxlength="2"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="班次说明" prop="description">
          <el-input
            v-model="ruleForm.description"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="班次颜色" prop="shiftColor">
          <div class="color-container">
            <div class="default-color-outside">
              <div
                class="default-color-inside"
                :style="{
                  background: ruleForm.shiftColor,
                }"
              >
                <span class="default-text">
                  {{
                    ruleForm.shiftShortName
                      ? ruleForm.shiftShortName.substring(0, 1)
                      : "默"
                  }}
                </span>
              </div>
              <span class="preview-span"> 预览 </span>
            </div>
            <div class="color-items">
              <div
                class="single-item"
                v-for="(item, index) in colorItems"
                :key="index"
                @click="setSettingColor(item)"
                :style="{
                  background: item,
                }"
              ></div>
            </div>
            <span class="customer-text"> 自定义 </span>
            <el-color-picker
              v-model="ruleForm.shiftColor"
              @change="setSettingColor()"
            ></el-color-picker>
          </div>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="班次时段" prop="workTime">
          <div
            class="work-time-container"
            v-for="(item, index) in workTimeList"
            :key="index"
          >
            <div>
              <span class="time-picker-span">
                <!-- <el-time-select
                  v-model="item.startTime"
                  style="width: 170px"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:59',
                  }"
                  placeholder="开始时间"
                >
                </el-time-select> -->
                <el-time-picker
                  style="width: 170px"
                  v-model="item.startTime"
                  format="HH:mm"
                  value-format="HH:mm"
                  placeholder="开始时间"
                >
                </el-time-picker>
                <span class="second-day-span" v-show="item.secondStart">
                  次日
                </span>
              </span>

              <span class="split-span"> 至 </span>

              <span class="time-picker-span">
                <el-time-picker
                  style="width: 170px"
                  v-model="item.endTime"
                  format="HH:mm"
                  value-format="HH:mm"
                  placeholder="结束时间"
                >
                </el-time-picker>
                <span class="second-day-span" v-show="item.secondEnd">
                  次日
                </span>
              </span>

              <i
                class="el-icon-delete"
                v-show="workTimeList.length > 1"
                @click="deleteWorkTimeLine(index)"
              ></i>
            </div>
            <span class="work-time-single">
              {{
                dealTime(
                  item.startTime,
                  item.endTime,
                  item.secondStart,
                  item.secondEnd,
                  true
                )
              }}
            </span>
          </div>
        </el-form-item>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-tooltip
          class="item"
          effect="dark"
          :disabled="workTimeList.length <= 2"
          content="最多添加三个时间段"
          placement="right"
        >
          <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            @click="addTimeLine"
            >添加时段</el-button
          >
        </el-tooltip>
      </el-row>
    </el-form>
    <div class="total-work-time">
      <span
        class="work-time-text"
        :style="{
          color: timeDiff > 86400000 ? '#ff0000' : '#000000',
        }"
      >
        班次总时长：{{ formatDuring(timeDiff) }}
      </span>
    </div>
    <div class="footer-container">
      <el-button @click="$emit('close', false)">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  createShiftSetting,
  updateShiftSetting,
} from "@/api/business/shifts/shiftSetting.js";
export default {
  name: "shiftSettingDialog",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const validatorWorkTime = (rule, value, callback) => {
      // 校验是否都填了
      for (let i = 0; i < this.workTimeList.length; i++) {
        if (!this.workTimeList[i].startTime) {
          callback(new Error("请选择开始时间"));
        }
        if (!this.workTimeList[i].endTime) {
          callback(new Error("请选择结束时间"));
        }
      }
      // 校验是否超过24小时
      if (this.formatDuring(this.timeDiff) === "不能超过24小时") {
        callback(new Error("排班时长不能超过24小时"));
      }
      // 校验单项是否短于30分钟
      for (let index = 0; index < this.workTimeList.length; index++) {
        const { startTime, endTime, secondStart, secondEnd } =
          this.workTimeList[index];
        const result = this.dealTime(
          startTime,
          endTime,
          secondStart,
          secondEnd,
          true
        );
        if (result === this.itemForbidden) {
          callback(new Error("单项排版时间不能少于半小时"));
          break;
        }
      }
      callback();
    };
    return {
      itemForbidden: "班次时最小半小时",
      timeDiff: 0,
      workTimeList: [
        {
          startTime: "",
          endTime: "",
          secondStart: false,
          secondEnd: false,
        },
      ],
      colorItems: [
        "#1A4CEC",
        "#72CE48",
        "#F8BA33",
        "#6A6AFF",
        "#FC6868",
        "#CCD64E",
        "#0293FF",
        "#CE91FF",
      ],
      ruleForm: {
        shiftName: "",
        shiftShortName: "",
        description: "",
        shiftColor: "#E4E4E4",
      },
      rules: {
        shiftName: [
          { required: true, message: "请填写班次名称", trigger: "blur" },
        ],
        shiftShortName: [
          { required: true, message: "请填写班次简称", trigger: "blur" },
        ],
        shiftColor: [
          { required: true, message: "请选择班次颜色", trigger: "blur" },
        ],
        workTime: [
          { required: true, validator: validatorWorkTime, trigger: "change" },
        ],
      },
    };
  },
  watch: {
    workTimeList: {
      handler() {
        this.checkTimeList();
      },
      deep: true,
    },
    dataset: {
      handler(value) {
        if (value.id) {
          this.displayDatas(value);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    endTimeRange(start) {
      return "17:00:00 - 16:00:00";
      console.log("startTime", start);
      if (!start) {
        return "00:00:00 - 23:59:00";
      }
      const currenttime = new Date();
      const currentStart = `${currenttime.getFullYear()}-${
        currenttime.getMonth() + 1
      }-${currenttime.getDate()} ${start}:00`;
      const startTime = new Date(currentStart);
      const endTime = new Date(startTime.getTime() + 30 * 60 * 1000);
      const endString = `${endTime.getHours()}:${endTime.getMinutes()}:00`;
      const startString = `${startTime.getHours()}:${startTime.getMinutes()}:00`;
      console.log("currentStart", startTime.getTime());
      console.log("currentStart12", `${startString} ${endString}`);
      return `${endString} - 23:59:00`;
    },
    displayDatas(datas) {
      let workTime = JSON.parse(datas.workTime);
      let tempObj = workTime.map((item) => {
        return {
          startTime: item.startTime.time,
          endTime: item.endTime.time,
          secondStart: item.startTime.workDay === "today",
          secondEnd: item.endTime.workDay === "today",
        };
      });
      this.workTimeList = tempObj;
      this.ruleForm = { ...datas };
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          return false;
        }
      });
    },
    submitHandler() {
      const params = this.buildParams();
      if (this.dataset.id) {
        updateShiftSetting(params).then((res) => {
          if (res === 1) {
            this.$message.success("修改班次成功！");
            this.$emit("close", true);
          }
        });
      } else {
        createShiftSetting(params).then((res) => {
          if (res === 1) {
            this.$message.success("创建班次成功！");
            this.$emit("close", true);
          }
        });
      }
    },
    buildParams() {
      let result = this.ruleForm;
      let tempObj = this.workTimeList.map((item) => {
        return {
          startTime: {
            time: item.startTime,
            workDay: item.secondStart ? "nextDay" : "today",
          },
          endTime: {
            time: item.endTime,
            workDay: item.secondEnd ? "nextDay" : "today",
          },
        };
      });
      result.workTime = JSON.stringify(tempObj);
      result.shiftDuration = this.formatDuring(this.timeDiff);
      return result;
    },
    checkTimeList() {
      this.workTimeList.forEach((item, index) => {
        if (
          (index > 0 &&
            item.startTime &&
            this.workTimeList[index - 1].endTime &&
            item.startTime <= this.workTimeList[index - 1].endTime) ||
          (this.workTimeList[index - 1] &&
            this.workTimeList[index - 1].secondEnd)
        ) {
          item.secondEnd = true;
          item.secondStart = true;
          return;
        } else {
          if (item.endTime && item.endTime <= item.startTime) {
            if (item.secondStart) {
              item.secondStart = false;
            }
            item.secondEnd = true;
          } else {
            item.secondStart = false;
            item.secondEnd = false;
          }
        }
      });
      this.checkTimeTotal();
    },
    checkTimeTotal() {
      this.timeDiff = 0;
      this.workTimeList.forEach((item) => {
        if (item.endTime && item.startTime) {
          this.dealTime(
            item.startTime,
            item.endTime,
            item.secondStart,
            item.secondEnd
          );
        }
      });
    },
    dealTime(startTime, endTime, secondStart, secondEnd, returnFlag) {
      const currentTime = new Date();
      const currentYear = currentTime.getFullYear();
      const currentMonth = currentTime.getMonth() + 1;
      const currentDay = currentTime.getDay();
      const itemStart = new Date(
        `${currentYear}-${currentMonth}-${currentDay} ${startTime}`
      );
      const itemEnd = new Date(
        `${currentYear}-${currentMonth}-${
          secondEnd && !secondStart ? currentDay + 1 : currentDay
        } ${endTime}`
      );
      // console.log("this.timeDiff", this.timeDiff);
      if (returnFlag) {
        if (!startTime || !endTime) {
          return "班次时长：0分钟";
        }
        if (
          itemEnd.getTime() > itemStart.getTime() &&
          itemEnd.getTime() - itemStart.getTime() < 30 * 60 * 1000
        ) {
          return this.itemForbidden;
        }
        return "班次时长：" + this.formatDuring(itemEnd - itemStart);
      } else {
        this.timeDiff += itemEnd - itemStart;
      }
    },
    formatDuring(mss) {
      const days = parseInt(mss / (1000 * 60 * 60 * 24));
      const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      // const seconds = (mss % (1000 * 60)) / 1000;
      if (days && minutes) {
        return "不能超过24小时";
      }
      return days
        ? "24小时"
        : hours
        ? `${hours}小时${minutes}分钟`
        : `${minutes}分钟`;
    },
    deleteWorkTimeLine(index) {
      this.workTimeList.splice(index, 1);
    },
    addTimeLine() {
      if (this.workTimeList.length > 2) return;
      this.workTimeList.push({
        startTime: "",
        endTime: "",
        secondStart: false,
        secondEnd: false,
      });
    },
    setSettingColor(color) {
      if (color) {
        this.ruleForm.shiftColor = color;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.shift-setting-dialog {
  .color-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    .default-color-outside {
      height: 36px;
      width: 36px;
      border: 1px solid #e4e4e4;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      position: relative;
      .default-color-inside {
        height: 26px;
        width: 26px;
        border-radius: 2px;
        line-height: 26px;
        text-align: center;
        .default-text {
          font-weight: 500;
          color: #ffffff;
          font-size: 14px;
        }
      }
      .preview-span {
        position: absolute;
        bottom: -30px;
        font-size: 12px;
      }
    }
    .color-items {
      display: inline-flex;
      margin-left: 50px;
      .single-item {
        height: 26px;
        width: 26px;
        margin-right: 10px;
        text-align: center;
        line-height: 26px;
        cursor: pointer;
        .el-icon-check {
          color: #ffffff;
        }
      }
    }
    .customer-text {
      font-size: 14px;
      font-weight: 500;
      color: #1a4cec;
      margin: 0 10px 0 20px;
    }
  }
  .work-time-container {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    .time-picker-span {
      position: relative;
      .second-day-span {
        position: absolute;
        right: 34px;
        top: -4px;
        display: inline-block;
        height: 24px;
        line-height: 12px;
        border: 1px solid #1a4cec;
        color: #1a4cec;
        border-radius: 4px;
        padding: 5px;
        font-size: 12px;
        cursor: default;
      }
    }
    .work-time-single {
      font-size: 14px;
      font-weight: bold;
      color: #2f3941;
      display: inline-block;
      width: 160px;
    }
  }
  .split-span {
    margin: 0 10px;
  }
  .el-icon-delete {
    font-size: 18px;
    margin-left: 20px;
    cursor: pointer;
  }
  .footer-container {
    margin: 20px 0;
    text-align: right;
  }
  .total-work-time {
    height: 40px;
    line-height: 40px;
    .work-time-text {
      font-size: 14px;
      font-weight: bold;
      color: #2f3941;
    }
  }
}
</style>