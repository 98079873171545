var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container shift-setting-list" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.createShifts } },
              [_vm._v("新建班次")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                attrs: { plain: "", icon: "el-icon-refresh-right" },
                on: { click: _vm.freshHandler },
              },
              [_vm._v("刷新")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header-filter-button",
            on: {
              click: function ($event) {
                _vm.dataset.filterLine.show = !_vm.dataset.filterLine.show
              },
            },
          },
          [
            _vm._v("\n      筛选\n      "),
            _vm.dataset.filterLine.show
              ? _c("i", { staticClass: "el-icon-arrow-down" })
              : _c("i", { staticClass: "el-icon-arrow-right" }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
            scopedSlots: _vm._u([
              {
                key: "shiftNameSlot",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "shift-color-span",
                        style: {
                          background: row.datas.shiftColor,
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              row.datas.shiftShortName
                                ? row.datas.shiftShortName.substring(0, 2)
                                : "默"
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(row.datas.shiftName) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "workTimeSlot",
                fn: function (row) {
                  return [
                    row.datas.workTime
                      ? _c(
                          "span",
                          _vm._l(
                            JSON.parse(row.datas.workTime),
                            function (item, index) {
                              return _c(
                                "span",
                                { key: index, staticClass: "single-time-span" },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.startTime.time) +
                                        "\n              "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.startTime.workDay ===
                                              "nextDay",
                                            expression:
                                              "item.startTime.workDay === 'nextDay'",
                                          },
                                        ],
                                        staticClass: "next-day-span",
                                      },
                                      [
                                        _vm._v(
                                          "\n                次日\n              "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v("\n            -\n            "),
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.endTime.time) +
                                        "\n              "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.endTime.workDay ===
                                              "nextDay",
                                            expression:
                                              "item.endTime.workDay === 'nextDay'",
                                          },
                                        ],
                                        staticClass: "next-day-span",
                                      },
                                      [
                                        _vm._v(
                                          "\n                次日\n              "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.dialogCloseHandler,
            width: "728px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.dialogTitle))]),
          ]),
          _vm.dialogVisible
            ? _c("ShiftSettingDialog", {
                attrs: { dataset: _vm.modifyDatas },
                on: { close: _vm.dialogCloseHandler },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }